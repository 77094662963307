<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormInstallmentPlan
		v-else
		:agent="paymentMethodDetails.agent"
		:is-submitting="installment.create.isCreating"
		:group-id="$route.params.id"
		:method-id="$route.params.methodId"
		:gateway="paymentMethodDetails.gateway"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FormInstallmentPlan from '@/components/FormInstallmentPlan.vue';
import { isBAYBankAgent } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';

export default {
	name: 'InstallmentPlanCreate',

	components: {
		FormInstallmentPlan,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('payments', ['installment']),

		...mapGetters({
			paymentMethodDetails: 'payments/paymentMethodDetails',
		}),

		isBAYBankAgent() {
			return this.paymentMethodDetails && isBAYBankAgent(this.paymentMethodDetails.agent);
		},
	},
	async created() {
		this.isLoading = true;
		await this.getPaymentMethod(this.$route.params.methodId);
		this.isLoading = false;

		const pageTitle = this.isBAYBankAgent ? 'Create default installment plan' : `Create ${this.paymentMethodDetails.name} installment plan`;

		this.setPageTitle({
			title: pageTitle,
			isShow: true,
		});
	},
	methods: {
		...mapActions({
			createPlan: 'payments/createInstallmentPlan',
			getPaymentMethod: 'payments/getPaymentMethod',
			setPageTitle: 'pageTitle/setPageTitle',
		}),

		async handleSubmit(params) {
			const methodId = this.$route.params.methodId;
			const groupId = this.$route.params.id;

			try {
				await this.createPlan({
					methodId,
					params,
				});

				this.$router.push({
					name: ROUTE_NAME.PAYMENT_METHOD_SETTING,
					params: { id: groupId, methodId },
				});
			} catch {
				// error
			}
		},
	},
};
</script>
